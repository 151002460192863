<template>
  <div class="container mt-5">
 <h1>Impressum</h1>
 <br>
    <p>India Treder & Simon Klein<br>
      Seestr. 2<br>
      30916 Isernhagen<br>
      Deutschland<br>
      trederklein@gmail.com
    </p>


    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter: 
      <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a>. 
      Als Kunde haben Sie jederzeit die Möglichkeit, die Schlichtungsstelle der Europäischen Kommission zu kontaktieren. 
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle der Europäischen Kommission teilzunehmen.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ImpressumPage'
}
</script>